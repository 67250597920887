<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingType">
          <el-select
              v-model="query.parkingType"
              clearable
              placeholder="请选择-车场类型"
          >
            <el-option label="路内车场" value="1"></el-option>
            <el-option label="路外车场" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingStatus">
          <el-select
              v-model="query.parkingStatus"
              clearable
              placeholder="请选择-车场状态"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
            <el-option label="待审核" value="2"></el-option>
            <el-option label="未通过" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="regionId">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>
        <el-form-item prop="settlementId">
          <!-- <el-input
            v-model="query.settlementName"
            placeholder="结算方名称"
          ></el-input> -->
          <Settlement v-model="query.settlementId"/>
        </el-form-item>

        <el-form-item prop="parkingName">
          <el-input
              v-model="query.parkingName"
              clearable
              placeholder="停车场名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('002001001001')"
              icon="add-select"
              @click.native="toAdd"
          />
          <icon-button
              v-if="hasRights('002001001002')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column fixed label="车场ID" prop="id" width="300">
          </el-table-column>
          <el-table-column fixed label="车场编号" prop="code" width="180">
          </el-table-column>
          <el-table-column label="车场名称" prop="name" width="240">
          </el-table-column>
          <el-table-column label="城区" prop="regionName" width="180">
          </el-table-column>
          <el-table-column label="车场类型" prop="parkingLotType" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.parkingLotType === 1">路内</span>
              <span v-if="scope.row.parkingLotType === 2">路外</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="deployMode" label="部署模式" width="180">
          </el-table-column> -->
          <el-table-column label="注册时间" prop="createDate" width="180">
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTime" width="180">
          </el-table-column>
          <el-table-column
              label="泊位数量"
              prop="parkingSpaceNumber"
              width="180"
          >
          </el-table-column>
          <!-- <el-table-column prop="" label="车场服务" width="180">
          </el-table-column> -->
          <el-table-column label="结算方名称" prop="settlementName" width="240">
          </el-table-column>
          <el-table-column label="车场状态" prop="parkingStatus" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0">正常</span>
              <span v-if="scope.row.status === 1">禁用</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              prop="name"
              width="240px"
          >
            <template slot-scope="scope">
              <el-button
                  v-if="hasRights('002001001003')"
                  size="small common-text"
                  type="text"
                  @click="toDetail(scope.row)"
              >详情
              </el-button
              >
              <el-button
                  v-if="scope.row.status === 0 && hasRights('002001001004')"
                  size="small common-text"
                  type="text"
                  @click="updateParkingLotStatus(scope.row.id, 1)"
              >禁用
              </el-button
              >
              <el-button
                  v-if="scope.row.status !== 0 && hasRights('002001001004')"
                  size="small common-text"
                  type="text"
                  @click="updateParkingLotStatus(scope.row.id, 0)"
              >启用
              </el-button
              >
              <el-button
                  v-if="hasRights('002001001005')"
                  size="small common-text"
                  type="text"
                  @click="doEdit(scope.row.id)"
              >编辑
              </el-button
              >
              <el-button
                  v-if="hasRights('002001001006')"
                  size="small common-text"
                  type="text"
                  @click="showPassage(scope.row)"
              >通道配置
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.pageNo"
              :page-size="pagination.pageSize"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="passageDialogVisible"
        append-to-body
        class="common-dialog"
        title="通道配置"
        width="900px"
    >
      <Passage
          ref="passage"
          :entryNumber="currentRow.entryNumber"
          :outNumber="currentRow.exportNumber"
          :parkingId="currentRow.id"
      />
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {exportParkingLotInfo, getParkingList, updateParkingLotStatus,} from "@/api/parking";
import {getRegionList} from "@/api/user";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import Settlement from "@/components/Settlement";
import Passage from "@/pages/parking/components/passage.vue";
import {doExport} from "@/api/common";

export default {
  name: "share_parking",
  mixins: [BaseMixin, Permission],
  components: {
    Settlement,
    Passage,
  },
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      query: {
        // 停车场类型
        parkingType: "",
        // 停车场状态
        parkingStatus: "",
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        parkingName: "",
        // 0-运营 1-机关事业单位 2-联网
        motionType: 1,
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [],
      nowStatus: "",
      passageDialogVisible: false,
      currentRow: {},
    };
  },
  mounted() {
  },
  methods: {
    showPassage(row) {
      this.passageDialogVisible = true;
      this.currentRow = row;

      this.$nextTick(() => {
        // this.$refs.passage.getGangtingDeviceList();
        this.$refs.passage.parkingId = row.id;
        // this.$refs.passage.getNumber();
        // this.$refs.passage.getGangtingDeviceList();
        this.$refs.passage.getParkingPassageList();
      });
    },
    onSuccess(e) {
      this.passageDialogVisible = e;
    },
    async doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
      const res = await getParkingList(this.paramFormat(this.query));
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.count;
        this.pagination.totalPages = returnObject.totalPage;
        this.form.exportSize = returnObject.count;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
          "/parkingLotManagementController/findParkingLotInfo",
          params,
          "停车场列表.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    updateParkingLotStatus(parkingId, status) {
      this.isLoading = true;
      if (status === 0) {
        this.nowStatus = "禁用";
      } else if (status === 1) {
        this.nowStatus = "启用";
      }
      this.$confirm(`此操作将${this.nowStatus}车场, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            updateParkingLotStatus({
              parkingId,
              status,
            }).then((res) => {
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                this.$message.success("操作成功！");
                this.doSearch();
              }
            });
          })
          .catch(() => {
            this.isLoading = false;
          });
    },
    doExport() {
      this.$prompt("请填写要导出的数量", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(({value}) => {
            const params = this.paramFormat(this.query);
            params["exportNumber"] = value;
            exportParkingLotInfo(this.paramFormat(this.query)).then((res) => {
              console.log(res);
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消",
            });
          });
    },
    doReset() {
      this.$refs.query.resetFields();
      // this.pagination.pageNo = 1;
      this.doSearch();
    },
    toAdd() {
      this.$router.push({
        path: "/share/parking_manage/share_parking_add",
        query: {
          motionType: this.query.motionType,
        },
      });
    },
    doEdit(item) {
      this.$router.push({
        path: "/share/parking_manage/share_parking_add",
        query: {
          parkingId: item,
          motionType: this.query.motionType,
        },
      });
    },
    toDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/share/parking_manage/share_parking_detail",
        query: {
          parkingId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
